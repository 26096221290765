import React, { useState } from 'react';
import axios from 'axios';

function Login({ onAuthentication }) {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = () => {
    axios
      .post('/api/Authenticate', {
        login: login,
        MotDePasse: password,
      })
      .then((response) => {
        if (response.status === 200) {
          setMessage('Authentification réussie');
          const token = response.data.token;
          localStorage.setItem('token', token);
          onAuthentication();
        }
      })
      .catch((error) => {
        setMessage(error.response.data);
      });
  };

  return (
    <div>
      <h1>Connexion</h1>
      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
        <div>
          <label>Nom d'utilisateur:</label>
          <input
            type="text"
            id="login"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Mot de passe:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Se connecter</button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
}

export default Login;
