import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ListProduitComponent = () => {
    const [produits, setProduits] = useState([]);
    const [reload, setReload] = useState(false);
    const [showUpdate, setShowUpdate] = useState({});
    const [searchReference, setSearchReference] = useState('');
    const [searchNom, setSearchNom] = useState('');


    useEffect(() => {
        const fetchProduits = async () => {
            try {
                const token = localStorage.getItem('token');

                const response = await axios.get('/api/item', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                setProduits(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        };

        fetchProduits();
    }, [reload]);

    const handleUpdate = async (itemId) => {
        try {
            const updatedProduct = produits.find(product => product.id === itemId);

            const formData = new FormData();
            formData.append('json', JSON.stringify(updatedProduct));

            if (updatedProduct.images && updatedProduct.images.length > 0) {
                Array.from(updatedProduct.images).forEach(image => {
                    formData.append('images', image);
                });
            }

            if (updatedProduct.fichier_pdf) {
                formData.append('fichier_pdf', updatedProduct.fichier_pdf);
            }

            const token = localStorage.getItem('token');

            const response = await axios.put(`/api/item/${itemId}`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setReload(prevState => !prevState);

            console.log(response.data);
            alert('Produit mis à jour avec succès');
        } catch (error) {
            console.error('Erreur lors de la mise à jour du produit :', error);
        }
    };

    const handleDelete = (id) => {
        const token = localStorage.getItem('token');

        axios.delete(`/api/item/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(() => {
                setReload(!reload);
            })
            .catch(error => {
                console.error('Erreur lors de la suppression du produit :', error);
            });
    };

    const handleInputChange = (id, field, value) => {
        setProduits(prevState => {
            const updatedProduits = prevState.map(produit => {
                if (produit.id === id) {
                    return {
                        ...produit,
                        [field]: value
                    };
                }
                return produit;
            });
            return updatedProduits;
        });
    };

    const toggleShowUpdate = (id) => {
        setShowUpdate(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const filteredProducts = produits.filter(produit =>
        produit.reference.toLowerCase().includes(searchReference.toLowerCase()) &&
        produit.nom.toLowerCase().includes(searchNom.toLowerCase())
    );

    return (
        <div>
            <div>
                <input
                    type="text"
                    placeholder="Recherche par référence"
                    value={searchReference}
                    onChange={(e) => setSearchReference(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Recherche par nom"
                    value={searchNom}
                    onChange={(e) => setSearchNom(e.target.value)}
                />
            </div>

            {filteredProducts.map(produit => (
                <div key={produit.id} style={{ margin: '10px', padding: '20px', border: '4px solid #ccc', borderRadius: '10px', overflow: 'auto' }}>
                    <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                        <p><strong>Référence :</strong> {produit.reference}</p>
                        <p style={{ margin: '0 50px' }}><strong>Nom :</strong> {produit.nom}</p>
                        <p ><strong>Prix :</strong></p><p style={{
                            textDecoration: produit.prix_mise_en_page === 'barré' ? 'line-through' : 'none'
                        }}> {produit.prix}</p>
                        <p style={{ margin: '0 50px' }}><strong>Catégorie :</strong> {produit.category}</p>
                        <button style={{ marginLeft: '20px' }} onClick={() => handleDelete(produit.id)}>Supprimer</button>
                        <button style={{ marginLeft: '20px' }} onClick={() => toggleShowUpdate(produit.id)}>Modifier</button>
                    </div>

                    <div className="update-section">
                        {showUpdate[produit.id] && (
                            <div><br />
                                <label htmlFor={`updatedReference_${produit.id}`}>Référence : </label>
                                <input
                                    id={`updatedReference_${produit.id}`}
                                    value={produit.reference}
                                    onChange={(e) => handleInputChange(produit.id, 'reference', e.target.value)}
                                /><br />

                                <label htmlFor={`updatedNom_${produit.id}`}>Nom : </label>
                                <input
                                    id={`updatedNom_${produit.id}`}
                                    value={produit.nom}
                                    onChange={(e) => handleInputChange(produit.id, 'nom', e.target.value)}
                                /><br />

                                <label htmlFor={`updatedPrix_${produit.id}`}>Prix : </label>
                                <input
                                    id={`updatedPrix_${produit.id}`}
                                    value={produit.prix}
                                    onChange={(e) => handleInputChange(produit.id, 'prix_mise_en_page', e.target.value)}
                                /><br />
                                <label htmlFor={`updatedPrix_mise_en_page_${produit.id}`}>Prix barré : </label>
                                <select
                                    id={`updatedPrix_mise_en_page_${produit.id}`}
                                    value={produit.prix_mise_en_page}
                                    onChange={(e) => handleInputChange(produit.id, 'prix_mise_en_page', e.target.value)}
                                >
                                    <option value="normal">Normal</option>
                                    <option value="barré">Barré</option>
                                </select><br />




                                <label htmlFor={`updatedDescriptif_${produit.id}`}>Descriptif : </label>
                                <textarea
                                    id={`updatedDescriptif_${produit.id}`}
                                    value={produit.descriptif}
                                    onChange={(e) => handleInputChange(produit.id, 'descriptif', e.target.value)}
                                /><br />

                                <label htmlFor={`updatedPromos_${produit.id}`}>Promos : </label>
                                <input
                                    id={`updatedPromos_${produit.id}`}
                                    value={produit.promos_text}
                                    onChange={(e) => handleInputChange(produit.id, 'promos_text', e.target.value)}
                                /><br />

                                <label htmlFor={`updatedResume_${produit.id}`}>Résumé : </label>
                                <input
                                    id={`updatedResume_${produit.id}`}
                                    value={produit.resume}
                                    onChange={(e) => handleInputChange(produit.id, 'resume', e.target.value)}
                                /><br />

                                <label htmlFor={`updatedCategory_${produit.id}`}>Catégorie : </label>
                                <input
                                    id={`updatedCategory_${produit.id}`}
                                    value={produit.category}
                                    onChange={(e) => handleInputChange(produit.id, 'category', e.target.value)}
                                /><br />
                                <label htmlFor={`updatedLien_video_${produit.id}`}>Lien video : </label>
                                <input
                                    id={`updatedLien_video_${produit.id}`}
                                    value={produit.lien_video_}
                                    onChange={(e) => handleInputChange(produit.id, 'lien_video', e.target.value)}
                                /><br />
                                <label htmlFor={`updatedImages_${produit.id}`}>Images : </label>
                                <input
                                    accept="image/*"
                                    type="file"
                                    id={`updatedImages_${produit.id}`}
                                    onChange={(e) => handleInputChange(produit.id, 'images', e.target.files)}
                                    multiple
                                /><br />

                                <label htmlFor={`updatedFichierPdf_${produit.id}`}>Fichier PDF : </label>
                                <input
                                    accept="application/pdf"
                                    type="file"
                                    id={`updatedFichierPdf_${produit.id}`}
                                    onChange={(e) => handleInputChange(produit.id, 'fichier_pdf', e.target.files[0])}
                                /><br />


                                <button onClick={() => handleUpdate(produit.id)}>Mettre à jour</button>
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ListProduitComponent;
