import React, { useState } from 'react';

import ItemTabComponent from './ItemTab';

const Insetcomp = () => {

    return (
        <div>

            <ItemTabComponent />
        </div>
    );
};

export default Insetcomp;
