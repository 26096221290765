import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SearchItem = () => {
  const [reference, setReference] = useState('');
  const [itemId, setItemId] = useState(null);
  const [item, setItem] = useState(null);
  const [mainImage, setMainImage] = useState(null);


  const handleSearch = () => {
    const token = localStorage.getItem('token');

    axios.get(`/api/item/reference/search?reference=${reference}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        const itemData = response.data;
        if (itemData && itemData.id) {
          setItemId(itemData.id);
          setItem(itemData);
          if (itemData.images && itemData.images.length > 0) {
            setMainImage(`http://biotika.tofeha.tn/photo/${itemData.images[0]}`);
          } else {
            setMainImage(null);
          }
        } else {
          console.error('L\'ID de l\'article n\'a pas été trouvé dans la réponse de l\'API.');
        }
      })
      .catch(error => {
        console.error('Erreur lors de la recherche de l\'item :', error);
      });
  };

  const handleThumbnailClick = (imageName) => {
    setMainImage(`http://biotika.tofeha.tn/photo/${imageName}`);
  };

  return (
    <div>
      <input
        type="text"
        value={reference}
        onChange={(e) => setReference(e.target.value)}
        placeholder="Entrez la référence de l'item"
      />
      <button onClick={handleSearch}>Rechercher</button>

      {item && (
        <div>
          <h2>Détails de l'Item</h2>
          <p>Référence : {item.reference}</p>
          <p>Nom : {item.nom}</p>
          <p>Prix :
            <span style={{
              textDecoration: item.prix_mise_en_page === 'barré' ? 'line-through' : 'none'
            }}>
              {item.prix}
            </span>
          </p>
          <p style={{ whiteSpace: "pre-line" }}>Description : {item.descriptif}</p>
          <p>Promotions : {item.promos_text}</p>
          <p>Résumé : {item.resume}</p>
          <p>Catégorie : {item.category}</p>
          <div>
            <h3>Images de l'Item</h3>
            {mainImage && <img src={mainImage} alt="Image principale" style={{ width: '100%' }} />}
            <div style={{ display: 'flex' }}>
              {item.images && item.images.map((imageName, index) => (
                <img
                  key={index}
                  src={`http://biotika.tofeha.tn/photo/${imageName}`}
                  alt={`Image ${index + 1}`}
                  onClick={() => handleThumbnailClick(imageName)}
                  style={{ width: '50px', height: 'auto', marginRight: '5px', cursor: 'pointer' }}
                />
              ))}
            </div>
          </div>
          <div>
            <h3>Fichiers PDF</h3>
            {Array.isArray(item.fichier_pdf) && item.fichier_pdf.map((pdfName, index) => (
              <div key={index}>
                <a href={`http://biotika.tofeha.tn/pdf/${pdfName}`} target="_blank" rel="noopener noreferrer">PDF {index + 1}</a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchItem;
