import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ListClientPanierComponent = () => {
    const [clients, setClients] = useState([]);
    const [reload, setReload] = useState(false);
    const [showUpdate, setShowUpdate] = useState({});
    const [searchCin, setSearchCin] = useState('');
    const [searchMatriculeFiscale, setSearchsearchMatriculeFiscale] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const token = localStorage.getItem('token');

                const response = await axios.get('/api/ClientPanier', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });

                setClients(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des données :', error);
            }
        };

        fetchClients();
    }, [reload]);

    const handleUpdate = (id) => {
        const updatedClient = clients.find(client => client.id === id);
        axios.put(`/api/ClientPanier/${id}`, updatedClient, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(() => {
                setReload(!reload);
                alert('Mise à jour effectuée avec succès');
            })
            .catch(error => {
                console.error('Erreur lors de la mise à jour du client :', error);
            });
    };

    const handleDelete = (nom) => {
        if (!nom) {
            console.error("L'identifiant du client panier est invalide.");
            return;
        }

        axios.delete(`/api/ClientPanier/${nom}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
            .then(() => {
                setReload(!reload);
                alert('Client panier supprimé avec succès');
            })
            .catch(error => {
                console.error('Erreur lors de la suppression du client panier :', error);
                alert('Erreur lors de la suppression du client panier');
            });
    };

    const handleInputChange = (id, field, value) => {
        setClients(prevState => {
            const updatedClients = prevState.map(client => {
                if (client.id === id) {
                    return {
                        ...client,
                        [field]: value
                    };
                }
                return client;
            });
            return updatedClients;
        });
    };

    const toggleShowUpdate = (id) => {
        setShowUpdate(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const filteredClients = clients.filter(client => {
        return client.cin.toLowerCase().includes(searchCin.toLowerCase()) &&
            client.matricule_fiscal.toLowerCase().includes(searchMatriculeFiscale.toLowerCase());
    });

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleShowProduct = (product) => {
        setSelectedProduct(product);
        setShowPopup(true);
    };

    const popupStyle = {
        position: 'fixed',
        top: '2px',
        left: '1px',
        width: '40%',
        height: '50%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    };

    const popupContentStyle = {
        backgroundColor: 'AliceBlue',
        padding: '200px',
        borderRadius: '100px',
        boxShadow: '0 0 10px rgba(1, 2, 2, 0.2)',
        display: 'inline-table',
    };

    const closeStyle = {
        position: 'absolute',
        top: '10px',
        right: '10px',
        cursor: 'pointer',
        fontSize: '20px',
        color: '#333'
    };

    return (
        <div>
            <br />
            <div>
                <div>
                    <span>Recherche par : </span>
                    <input
                        type="text"
                        placeholder="C.I.N"
                        value={searchCin}
                        onChange={(e) => setSearchCin(e.target.value)}
                    />
                </div>
                <div><br />
                    <span>Recherche par : </span>
                    <input
                        type="text"
                        placeholder="Matricule Fiscale"
                        value={searchMatriculeFiscale}
                        onChange={(e) => setSearchsearchMatriculeFiscale(e.target.value)}
                    />
                </div>
            </div>

            {filteredClients.map(client => (
                <div key={client.id} style={{ margin: '10px', padding: '20px', border: '4px solid #ccc', borderRadius: '10px', overflow: 'auto' }}>
                    <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                        <span><strong>Nom :</strong> {client.nom}</span>
                        <span style={{ margin: '0 50px' }}><strong>Prénom :</strong> {client.prenom}</span>
                        <span style={{ margin: '0 50px' }}><strong>Adresse :</strong> {client.adresse}</span>
                        <span style={{ margin: '0 50px' }}><strong>Code postal :</strong> {client.code_postal}</span>
                        <span style={{ margin: '0 50px' }}><strong>Ville :</strong> {client.ville}</span>
                        <span style={{ margin: '0 50px' }}><strong>Matricule fiscal :</strong> {client.matricule_fiscal}</span>
                        <span style={{ margin: '0 50px' }}><strong>Quantité :</strong> {client.quantite}</span>
                        <button style={{ marginLeft: '20px' }} onClick={() => handleDelete(client.nom)}>Supprimer</button>
                        <button style={{ marginLeft: '20px' }} onClick={() => toggleShowUpdate(client.id)}>Modifier</button>
                        <button style={{ marginLeft: '20px' }} onClick={() => handleShowProduct(client)}>Afficher</button>
                    </div>

                    <div className="update-section">
                        {showUpdate[client.id] && (
                            <div><br />
                                <label htmlFor={`updatedNom_${client.id}`}>Nom : </label>
                                <input
                                    id={`updatedNom_${client.id}`}
                                    value={client.nom}
                                    onChange={(e) => handleInputChange(client.id, 'nom', e.target.value)}
                                />
                                <br />
                                <label htmlFor={`updatedPrenom_${client.id}`}>Prénom : </label>
                                <input
                                    id={`updatedPrenom_${client.id}`}
                                    value={client.prenom}
                                    onChange={(e) => handleInputChange(client.id, 'prenom', e.target.value)}
                                />
                                <br />
                                <label htmlFor={`updatedEmail_${client.id}`}>Email : </label>
                                <input
                                    id={`updatedEmail_${client.id}`}
                                    value={client.email}
                                    onChange={(e) => handleInputChange(client.id, 'email', e.target.value)}
                                />
                                <br />
                                <label htmlFor={`updatedMot_de_passe_${client.id}`}>Mot de passe : </label>
                                <input
                                    id={`updatedmot_de_passe_${client.id}`}
                                    value={client.mot_de_passe}
                                    onChange={(e) => handleInputChange(client.id, 'mot de passe', e.target.value)}
                                />
                                <br />
                                <label htmlFor={`updatedAdresse_${client.id}`}>Adresse : </label>
                                <input
                                    id={`updatedAdresse_${client.id}`}
                                    value={client.adresse}
                                    onChange={(e) => handleInputChange(client.id, 'adresse', e.target.value)}
                                />
                                <br />
                                <label htmlFor={`updatedCode_postal_${client.id}`}>Code Postal : </label>
                                <input
                                    id={`updatedCode_postal_${client.id}`}
                                    value={client.code_postal}
                                    onChange={(e) => handleInputChange(client.id, 'code_postal', e.target.value)}
                                />
                                <br />
                                <label htmlFor={`updatedVille_${client.id}`}>Ville : </label>
                                <input
                                    id={`updatedVille_${client.id}`}
                                    value={client.ville}
                                    onChange={(e) => handleInputChange(client.id, 'ville', e.target.value)}
                                />
                                <br />
                                <label htmlFor={`updatedMatricule_fiscal_${client.id}`}>Matricule Fiscal : </label>
                                <input
                                    id={`updatedMatricule_fiscal_${client.id}`}
                                    value={client.matricule_fiscal}
                                    onChange={(e) => handleInputChange(client.id, 'matricule_fiscal', e.target.value)}
                                />
                                <br />
                                <label htmlFor={`updatedCin_${client.id}`}>C.I.N : </label>
                                <input
                                    id={`updatedCin_${client.id}`}
                                    value={client.cin}
                                    onChange={(e) => handleInputChange(client.id, 'cin', e.target.value)}
                                />
                                <br />
                                <label htmlFor={`updatedId_article_${client.id}`}>ID Article : </label>
                                <input
                                    id={`updatedId_article_${client.id}`}
                                    value={client.id_article}
                                    onChange={(e) => handleInputChange(client.id, 'id_article', e.target.value)}
                                />
                                <br />

                                <label htmlFor={`updatedQuantite__${client.id}`}>Quantité : </label>
                                <input
                                    id={`updatedQuantite_${client.id}`}
                                    value={client.quantite}
                                    onChange={(e) => handleInputChange(client.id, 'quantite', e.target.value)}
                                />
                                <br />
                            </div>

                        )}
                    </div>
                </div>
            ))}
            {/* Affichage du popup pour les détails du produit sélectionné */}
            {selectedProduct && showPopup && (
                <div style={popupStyle}>
                    <div style={popupContentStyle}>
                        <span style={closeStyle} onClick={handleClosePopup}>&times;</span>
                        <p>Nom : <span>{selectedProduct.nom}</span></p>
                        <p>Prénom : {selectedProduct.prenom}</p>
                        <p>Adresse : {selectedProduct.adresse}</p>
                        <p>Code Postal : {selectedProduct.code_postal}</p>
                        <p>Ville : {selectedProduct.Ville}</p>
                        <p>Matricule fiscal : {selectedProduct.matricule_fiscal}</p>
                        <p>Numéro de téléphone : {selectedProduct.numero_telephone}</p>
                        <p>Quantité : {selectedProduct.quantite}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListClientPanierComponent;
