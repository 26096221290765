import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Login from './login';

const Root = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleAuthentication = () => {
    setIsAuthenticated(true);
  };

  return isAuthenticated ? <App /> : <Login onAuthentication={handleAuthentication} />;
};

ReactDOM.render(
  <React.StrictMode>
    <Root />
  </React.StrictMode>,
  document.getElementById('root')
);
