import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AddCategoryForm = ({ selectedCategory }) => {
  const [nomCategorie, setNomCategorie] = useState(selectedCategory || '');
  const [newCategoryName, setNewCategoryName] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    setNomCategorie(selectedCategory || '');
  }, [selectedCategory]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post('/api/addTable/Category', { nomCategorie, newCategoryName }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log("Réponse du serveur :", response.data);

      loadCategories();
    } catch (error) {
      console.error('Erreur lors de l\'ajout de la catégorie :', error);
    }
  };

  const loadCategories = () => {
    const token = localStorage.getItem('token');
    axios.get('/api/Allcategory', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setCategories(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des catégories :', error);
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  const handleDeleteCategory = async (categoryToDelete) => {
    try {
      const token = localStorage.getItem('token');
      console.log("Données envoyées au serveur :", { nomSousCategorie: categoryToDelete });
      const response = await axios.delete('/api/deleteSousCategory', {
        headers: {
          Authorization: `Bearer ${token}`
        },
        data: {
          nomSousCategorie: categoryToDelete
        }
      });
      console.log("Réponse du serveur :", response.data);

      loadCategories();
    } catch (error) {
      console.error('Erreur lors de la suppression de la catégorie :', error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Ajouter sous catégorie"
          value={newCategoryName}
          onChange={(e) => setNewCategoryName(e.target.value)}
        />
        <button type="submit">Ajouter la sous-catégorie</button>
      </form>
      <div style={{ marginBottom: '10px' }}>
        <label>Sous Catégorie : </label>
        <select value={nomCategorie} onChange={(e) => setNomCategorie(e.target.value)}>
          <option value="">Sélectionner la sous catégorie</option>
          {categories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
        <button onClick={() => handleDeleteCategory(nomCategorie)}>Supprimer</button>
      </div>
    </>
  );
};

export default AddCategoryForm;
