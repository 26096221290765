import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AddCategoryForm from './AddCategoryForm';

const TableManager = () => {
  const [tables, setTables] = useState([]);
  const [selectedTable, setSelectedTable] = useState('');
  const [newTableName, setNewTableName] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token');

    axios.get('/api/addTable', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setTables(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des tables :', error);
      });
  }, []);

  const handleAddTable = () => {
    const token = localStorage.getItem('token');

    axios.post('/api/addTable', { nomTable: newTableName }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(() => {
        setNewTableName('');
        fetchTables();
      })
      .catch(error => {
        console.error('Erreur lors de l\'ajout de la table :', error);
      });
  };

  const handleDeleteTable = () => {
    const token = localStorage.getItem('token');

    axios.delete(`/api/addTable/${selectedTable}`, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(() => {
        setSelectedTable('');
        fetchTables();
      })
      .catch(error => {
        console.error('Erreur lors de la suppression de la table :', error);
      });
  };

  const fetchTables = () => {
    const token = localStorage.getItem('token');

    axios.get('/api/addTable', {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(response => {
        setTables(response.data);
      })
      .catch(error => {
        console.error('Erreur lors de la récupération des tables :', error);
      });
  };

  return (
    <div>
      <h2>Liste des catégories</h2>
      <select value={selectedTable} onChange={e => setSelectedTable(e.target.value)}>
        <option>selectionner une categorie</option>
        {tables.map(table => (
          <option key={table} value={table}>{table}</option>
        ))}
      </select>
      <button onClick={handleDeleteTable} disabled={!selectedTable}>Supprimer la catégorie</button>

      <input
        type="text"
        placeholder="Ajouter une catégorie"
        value={newTableName}
        onChange={e => setNewTableName(e.target.value)}
      />
      <button onClick={handleAddTable} disabled={!newTableName}>Ajouter une catégorie</button>

      <AddCategoryForm selectedCategory={selectedTable} />

    </div>
  );
};

export default TableManager;
